import React, { useState, useMemo } from 'react'
import {
  InputWrapper,
  CoreInput,
  ErrorMessage,
  MainWrapper,
  ClearButton,
  IconWrapper
} from './styled'
import { Label, Icon, Button } from 'components'

const SearchInput = (props) => {
  const {
    onChange,
    returnValueOnChange = true,
    value = '',
    label,
    placeholder,
    error,
    tooltip,
    onClickOverride,
    icon,
    type,
    onSearch,
    withButton = true,
    clear,
    handleClear,
    transparent,
    ...inputProps
  } = props

  const showClear = useMemo(() => {
    if (clear && value.length) {
      return true
    } else {
      return false
    }
  }, [clear, value])

  return (
    <MainWrapper>
      <InputWrapper
        $transparent={transparent}
        onClick={onClickOverride}
        $withError={error ? true : false}
      >
        {icon && (
          <IconWrapper>
            <Icon name="search"></Icon>
          </IconWrapper>
        )}

        <CoreInput
          value={value}
          onChange={(e) => {
            if (returnValueOnChange) {
              onChange(e.target.value)
            } else {
              onChange(e)
            }
          }}
          // onKeyDown={(e) => (e.key === 'Enter' ? onSearch() : {})}
          placeholder={placeholder}
          type={'text'}
          autoComplete="off"
          enterKeyHint="search"
          {...inputProps}
        />
        {showClear && (
          <ClearButton onClick={handleClear}>
            <Icon name="close" />
          </ClearButton>
        )}
        {withButton && <Button onClick={onSearch}>Search</Button>}
      </InputWrapper>
    </MainWrapper>
  )
}

export default SearchInput
